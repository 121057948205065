import React, { ComponentProps, FC } from 'react'

import { cx, css } from '@linaria/core'
import { styled } from '@linaria/react'

import { WithChildren } from 'common/types'

import { buttonResetCss } from '../styles/buttonResetCss'

export const RoundButton: FC<
  { size: Size; interactive?: boolean } & WithChildren &
    ComponentProps<typeof RoundButtonInner>
> = ({ size, interactive = true, children, ...restProps }) => {
  return (
    <RoundButtonInner
      className={cx(sizeStylesMap[size], interactive && interactiveCss)}
      {...restProps}
      as={interactive ? undefined : 'div'}
    >
      {children}
    </RoundButtonInner>
  )
}

type Size = 'XL' | 'L' | 'M' | 'S' | 'XS'

const interactiveCss = css`
  @media (hover: hover) {
    &:hover {
      background-color: var(--accent-primary-hover, #5c504b);
    }
  }

  &:active {
    background-color: var(--accent-primary-press, #7b6b64);
  }

  &:disabled {
    background: var(--background-opacity-disabled, rgba(98, 127, 153, 0.24));
    cursor: default;
  }
`

const RoundButtonInner = styled.button`
  ${buttonResetCss};
  background-color: var(--accent-primary-default, #2e2a29);
  transition: 0.2s;
`

const sizeStylesMap: Record<Size, string> = {
  XL: css`
    padding: var(--spacing-20px, 20px);
    border-radius: var(--spacing-48px, 48px);
  `,
  L: css`
    padding: var(--spacing-16px, 16px);
    border-radius: var(--spacing-48px, 48px);
  `,
  M: css`
    padding: var(--spacing-12px, 12px);
    border-radius: var(--spacing-48px, 48px);
  `,
  S: css`
    padding: var(--spacing-8px, 8px);
    border-radius: var(--spacing-80px, 80px);
  `,
  XS: css`
    padding: var(--spacing-6px, 6px);
    border-radius: var(--spacing-80px, 80px);
  `,
}
