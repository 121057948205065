import { LOGOUT_ACTION } from 'actions/authorization/logoutAction'
import { AuthorizationTypes } from 'actions/authorizationAction'
import {
  NotificationActionTypes,
  REMOVE_NOTIFICATION,
} from 'actions/notificationAction'
import {
  RESTORE_INITIAL_STATE,
  RestoreInitialStateAction,
} from 'actions/system/restoreInitialStateAction'
import { RESET_UNI_NOTICES } from 'actions/uninotice/resetUniNoticesAction'
import { TOGGLE_INLINE_NOTICE } from 'actions/uninotice/toggleInlineNoticeAction'
import {
  ADD_NOTICE_TO_COLLECTION,
  CLEAR_INLINE_NOTICE,
  FETCH_INLINE_NOTICE_PAGE,
  FETCH_UNI_NOTICE,
  UniNoticeTypes,
} from 'actions/uninotice/uniNoticeAction'
import { UniNotice } from 'api/uninotice/UniNotice'
import { UniNoticeId } from 'components/uninotice/uninotice.types'
import { initialStateReducer } from 'reducers/default/initialStateReducer'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

export interface UniNoticeState {
  activeInline: Record<UniNoticeId | string, boolean>
  collection: Record<UniNoticeId | string, UniNotice>
  inlineNoticePage: UniNotice | null
  noticeLoading: boolean
  noticeNotFound: boolean
}

export const uniNoticeReducer = (
  state: UniNoticeState = {
    activeInline: {},
    collection: {},
    inlineNoticePage: null,
    noticeLoading: false,
    noticeNotFound: false,
  },
  action:
    | UniNoticeTypes
    | NotificationActionTypes
    | AuthorizationTypes
    | RestoreInitialStateAction
): UniNoticeState => {
  switch (action.type) {
    case ADD_NOTICE_TO_COLLECTION:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.id]: action.result,
        },
      }

    case REMOVE_NOTIFICATION:
      delete state.collection[action.id]
      delete state.activeInline[action.id]

      return {
        ...state,
        activeInline: {
          ...state.activeInline,
        },
        collection: {
          ...state.collection,
        },
      }

    case TOGGLE_INLINE_NOTICE:
      return {
        ...state,
        activeInline: {
          ...state.activeInline,
          [action.result.uniNoticeId]: action.result.value,
        },
      }

    case RESET_UNI_NOTICES:
    case LOGOUT_ACTION:
      return {
        ...state,
        activeInline: {},
        collection: {},
        noticeNotFound: false,
      }

    case FETCH_INLINE_NOTICE_PAGE:
      return defaultPromiseReducer<UniNoticeState, UniNotice>(
        state,
        action,
        undefined,
        ({ noticeId, payload, type }) => ({
          inlineNoticePage: {
            ...state.inlineNoticePage,
            payload,
            noticeId,
            type,
          },
        }),
        (_result, _state, _error, errorStatus) => {
          return {
            noticeNotFound: errorStatus === 404,
          }
        }
      )

    case FETCH_UNI_NOTICE:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ noticeNotFound: false, noticeLoading: true }),
        () => ({ noticeLoading: false }),
        (_result, _state, _error, errorStatus) => ({
          noticeLoading: true,
          noticeNotFound: errorStatus !== 200,
        })
      )

    case CLEAR_INLINE_NOTICE:
      return {
        ...state,
        inlineNoticePage: null,
      }

    case RESTORE_INITIAL_STATE:
      return initialStateReducer(state, action, 'uniNoticeReducer')

    default:
      return state
  }
}
