import React, {
  ChangeEventHandler,
  ClipboardEventHandler,
  FC,
  FormEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { VoidHandler } from 'common/types'
import { Input } from 'components/designSystem/Input/Input'
import { MainLayout } from 'components/designSystem/layout/MainLayout/MainLayout'
import { Typography } from 'components/designSystem/Typography/Typography'

import { PhoneVerificationAction, PhoneVerificationMethod } from './constants'
import { EnterVerificationCodeDescription } from './EnterVerificationCodeDescription'
import { TimerText } from './TimerText'
import { NextStepButton } from '../Onboarding/NextStepButton'

export const EnterVerificationCode: FC<{
  active: boolean
  timer: number
  phoneVerificationMethod: PhoneVerificationMethod
  phoneVerificationAction: PhoneVerificationAction | null
  phoneNumber: string
  onSubmit: (code: string) => void
  onResendCode: VoidHandler
  onChangeVerificationMethod?: VoidHandler
  disabled?: boolean
  error?: boolean
  loading?: boolean
  codeLength: number
  backButtonVisible?: boolean
}> = ({
  active,
  timer,
  phoneVerificationMethod,
  phoneVerificationAction,
  phoneNumber,
  onSubmit,
  onResendCode,
  onChangeVerificationMethod,
  disabled,
  error,
  loading,
  codeLength,
  backButtonVisible,
}) => {
  const [code, setCode] = useState<string[]>(createCodeInitialValue(codeLength))

  const inputsRef = useRef<(HTMLInputElement | null)[]>(
    new Array(codeLength).fill(undefined)
  )

  const handleSubmit = () => {
    onSubmit(code.join(''))
  }

  useEffect(() => {
    if (code.every(Boolean)) {
      onSubmit(code.join(''))
    }
  }, [code, onSubmit])

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target as HTMLInputElement
      const index = Number(target.dataset.index)

      if (event.key === 'Backspace' && !target.value && index > 0) {
        inputsRef.current[index - 1]!.focus()
      }
    },
    []
  )

  const handleInput: FormEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target as HTMLInputElement
      const index = Number(target.dataset.index)

      if (target.value && index < inputsRef.current.length - 1) {
        inputsRef.current[index + 1]!.focus()
      }
    },
    []
  )

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target as HTMLInputElement

      setCode((code) => {
        const inputIndex = Number(target.dataset.index)
        const firstEmptyInputIndex = code.findIndex((value) => value === '')
        /**
         * Если мы пытаемся ввести код в какой-то из инпутов,
         * но если есть пустой инпут левее - тогда мы вставим значение в первый пустой инпут.
         * Нужно для кейса, когда код берется из смс и вставляется через механизм быстрой вставки.
         * В случае, если фокус стоит не на первом поле, тогда код из смс вставится начиная с текущего инпута.
         * Что приведет к ошибке.
         */
        const index =
          firstEmptyInputIndex >= 0 && firstEmptyInputIndex < inputIndex
            ? firstEmptyInputIndex
            : inputIndex

        const _code = [...code]
        _code[index] = inputsRef.current[inputIndex]?.value || ''
        return _code
      })
    },
    []
  )

  const handlePaste: ClipboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.preventDefault()
      // @ts-expect-error
      const clipboardData = event.clipboardData || window.clipboardData
      const pastedData = clipboardData.getData('text')

      if (
        pastedData &&
        !isNaN(Number(pastedData)) &&
        pastedData.length > 1 &&
        pastedData.length <= codeLength
      ) {
        setCode([...pastedData])
      }
    },
    [codeLength]
  )

  const handleResendCode = () => {
    setCode(createCodeInitialValue(codeLength))
    onResendCode()
  }
  const handleChangeVerificationMethod = () => {
    setCode(createCodeInitialValue(codeLength))
    onChangeVerificationMethod?.()
  }

  return (
    <MainLayout
      loading={loading}
      title={
        <FormattedMessage
          id="app.confirmation_code"
          defaultMessage="Проверочный код"
        />
      }
      titleFontSize={32}
      description={
        <EnterVerificationCodeDescription
          phoneVerificationMethod={phoneVerificationMethod}
          phoneNumber={phoneNumber}
        />
      }
      children={
        <>
          <InputsBlock>
            {[...new Array(codeLength)].map((_, index) => (
              <Input
                key={index}
                value={code[index]}
                type="text"
                autoComplete="one-time-code"
                data-index={index}
                data-name={`code-input-${index + 1}`}
                ref={(node) => {
                  inputsRef.current[index] = node
                }}
                style={{ textAlign: 'center' }}
                maxLength={1}
                inputMode="numeric"
                onKeyDown={handleKeyDown}
                onInput={handleInput}
                onChange={handleChange}
                onPaste={handlePaste}
                disabled={disabled}
                invalid={error}
              />
            ))}
          </InputsBlock>

          <InfoBlock>
            <Text fontSize={14}>
              <TimerText
                seconds={timer}
                error={error}
                phoneVerificationMethod={phoneVerificationMethod}
                onResendCode={handleResendCode}
                phoneVerificationAction={phoneVerificationAction}
                onChangeVerificationMethod={handleChangeVerificationMethod}
                loading={loading}
              />
            </Text>
          </InfoBlock>

          <NextStepButton
            active={code.every(Boolean)}
            visible={active}
            loading={loading}
            onClick={handleSubmit}
          />
        </>
      }
      backButtonVisible={backButtonVisible}
      noBorder
    />
  )
}

const createCodeInitialValue = (length: number) => new Array(length).fill('')

const InfoBlock = styled.div`
  margin-top: var(--spacing-8px, 8px);
  text-align: center;
`
const Text = styled(Typography)`
  color: var(--foreground-muted, #99908e);
`
const InputsBlock = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    width: 42px;
  }

  & > * + * {
    html[dir='ltr'] & {
      margin-left: var(--spacing-6px, 6px);
    }
    html[dir='rtl'] & {
      margin-right: var(--spacing-6px, 6px);
    }
  }
`
