import React, { FC } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'
import { animated, useTransition } from '@react-spring/web'
import { createPortal } from 'react-dom'

import { VoidHandler, WithClassName } from 'common/types'
import { RoundButton } from 'components/designSystem/RoundButton/RoundButton'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { buttonResetCss } from 'components/designSystem/styles/buttonResetCss'
import { ArrowRightSvg } from 'components/designSystem/svgr/ArrowRightSvg'
import { ButtonLoaderSvg } from 'components/designSystem/svgr/ButtonLoaderSvg'

export const NextStepButton: FC<
  {
    visible: boolean
    active: boolean
    loading?: boolean
    onClick: VoidHandler
  } & WithClassName
> = ({ className, visible, active, loading, onClick }) => {
  const transitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
  })

  const portalNode =
    process.env.browser && document.getElementById(NEXT_STEP_BUTTON_PORTAL_ID)

  const element = transitions(
    (springs, show) =>
      show && (
        <Circle
          style={springs}
          className={cx(active ? activeCss : disabledCss, className)}
        >
          <RoundButton
            size="XL"
            disabled={!active}
            onClick={active ? onClick : undefined}
            data-name="next-step-action"
          >
            {loading ? (
              <StyledLoader width="32" height="32" />
            ) : (
              <StyledArrowRight width="32" height="32" />
            )}
          </RoundButton>
        </Circle>
      )
  )

  if (!portalNode) {
    return element
  }

  return createPortal(element, portalNode)
}

const NEXT_STEP_BUTTON_PORTAL_ID = 'next-step-button-portal-id'

export const NextStepButtonPortal: FC = () => {
  return <div id={NEXT_STEP_BUTTON_PORTAL_ID}></div>
}

const StyledLoader = styled(ButtonLoaderSvg)``
const StyledArrowRight = styled(ArrowRightSvg)``
const Circle = styled(animated.div)`
  ${buttonResetCss};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 40px;
  bottom: 40px;
  @media screen and (max-width: ${breakpoints.mobile - 1}px) {
    right: 16px;
    bottom: 16px;
  }
  border-radius: 50%;
`
const disabledCss = css`
  path {
    fill: var(--foreground-disabled, #627f9966);
  }
  cursor: default;
`
const activeCss = css`
  path {
    fill: white;
    fill-opacity: 1;
  }
`
