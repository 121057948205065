import { useState, useEffect } from 'react'

import { usePrevious } from 'hooks/usePrevious'

/** Хук добавляет асинхронную работу с шагами для корректного отображения */
export const useStepSlider = <T>(step: number, steps: T[]) => {
  const [_step, setStep] = useState(step)
  const [_steps, setSteps] = useState(steps)
  const prevStep = usePrevious(step)

  useEffect(() => {
    // Если мы переходим вперед, то нам нужно моментально установить новые steps
    if (step > prevStep) {
      setSteps(steps)
    }
    // setTimeout нужен для того, чтобы отработал весь js и установились новые steps.
    // это исправит баг с тем, что экран следующего шага рисуется в процессе перехода на этот экран
    window.setTimeout(() => {
      setStep(step)
    }, 100)
    // При переходе на предыдущий шаг, мы убираем экран. Из-за этого, он исчезает моментально.
    // Чтобы это исправить, добавляем задержку в 1 секунду.
    if (step < prevStep) {
      window.setTimeout(() => {
        setSteps(steps)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  return {
    step: _step,
    steps: _steps,
  }
}
