import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'

import { PhoneVerificationMethod } from './constants'

export const EnterVerificationCodeDescription: FC<{
  phoneVerificationMethod: PhoneVerificationMethod
  phoneNumber: string
}> = ({ phoneVerificationMethod, phoneNumber }) => {
  switch (phoneVerificationMethod) {
    case PhoneVerificationMethod.Sms:
      return (
        <FormattedMessage
          id="app.we_sent_phone_code_to_number"
          defaultMessage="Мы отправили проверочный код на номер:{br}{phoneNumber}."
          values={{
            phoneNumber,
            br: <br />,
          }}
        />
      )
    case PhoneVerificationMethod.Call:
      return (
        <FormattedMessage
          id="app.enter.call.verification.code"
          defaultMessage="Сейчас на номер{br}{phoneNumber} поступит звонок. Введите последние 4 цифры номера."
          values={{
            br: <br />,
            phoneNumber,
          }}
        />
      )
    case PhoneVerificationMethod.VoiceCode:
      return (
        <FormattedMessage
          id="form.advice_text.voice_code"
          defaultMessage="Вам поступит звонок, возьмите трубку и прослушайте код."
        />
      )
    case PhoneVerificationMethod.MobileId:
      return null
    default:
      return null
  }
}
